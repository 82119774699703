// src/pages/titles/SchedulerInviteUserPage.jsx

import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, useNavigate } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Loading from '../../component/alerts/Loading';
import Danger from '../../component/alerts/Danger';
import Success from '../../component/alerts/Success';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import timeouts from '../../../../constants/timeouts';

const SchedulerInviteUserPage = () => {
    const { id } = useParams();
    const [scheduler, setScheduler] = useState(null);
    const [title, setTitle] = useState(null);
    const [communityUsers, setCommunityUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isInviting, setIsInviting] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!Glitch.util.Session.isLoggedIn()) {
            const currentUrl = window.location.href;
            navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
        } else {
            fetchTitleData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const fetchTitleData = async () => {
        try {
            const reponseScheduler = (await Glitch.api.Scheduler.getSchedule(id));

            const responseTitle = await Glitch.api.Titles.view(reponseScheduler.data.data.title_id);
            setScheduler(reponseScheduler.data.data);
            setTitle(responseTitle.data.data);

            if (responseTitle.data.data.community_id) {
                fetchCommunityUsers(responseTitle.data.data.community_id);
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            console.error('Error fetching title data:', error);
            setErrors({ message: 'Error fetching title data' });
            setIsLoading(false);
        }
    };

    const fetchCommunityUsers = async (communityId) => {
        try {
            const response = await Glitch.api.Communities.listUsers(communityId);
            setCommunityUsers(response.data.data);
            setFilteredUsers(response.data.data);
        } catch (error) {
            console.error('Error fetching community users:', error);
            setErrors({ message: 'Error fetching community users' });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);

        const filtered = communityUsers.filter((userWrapper) =>
            userWrapper?.user?.username.toLowerCase().includes(term.toLowerCase())
        );
        setFilteredUsers(filtered);
    };

    const handleInviteUser = async () => {
        if (!selectedUserId) {
            setErrors({ message: 'Please select a user to invite' });
            return;
        }

        setIsInviting(true);

        try {
            await Glitch.api.Titles.addAdministrator(title.id, { user_id: selectedUserId });
            setSuccess('User has been added as an administrator.');
            setTimeout(() => {
                navigate(Navigate.schedulerUsersPage(scheduler?.id));
            }, timeouts.redirect_timeout);
        } catch (error) {
            console.error('Error adding administrator:', error);
            let errorMessage = 'Error adding administrator.';
            if (error.response && error.response.status === 409) {
                errorMessage = 'User is already an administrator for this title.';
            }
            setErrors({ message: errorMessage });
        } finally {
            setIsInviting(false);
        }
    };

    const handleInviteNewUser = () => {
        navigate(Navigate.communitiesUsersInvitePage(title.community_id));
    };

    if (isLoading) {
        return (
            <>
                <PublisherHeader position={"relative"} />
                <div className="container my-5 text-center">
                    <Loading />
                </div>
                <Footer />
            </>
        );
    }

    if (!title.community_id) {
        return (
            <>
                <PublisherHeader position={"relative"} />
                <div className="container mt-5">
                    <div className="alert alert-warning">
                        This title does not belong to a community/business account. To invite users, please associate this title with a community.
                    </div>
                </div>
                <Footer />
            </>
        );
    }

    return (
        <>
            <PublisherHeader position={"relative"} />
            <div className="container mt-4">
                <Breadcrumbs items={[
                    { name: 'Schedulers', link: Navigate.schedulerListPage() },
                    { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
                    { name: 'Users', link: Navigate.schedulerUsersPage(scheduler?.id) },
                    { name: 'Invite', link: Navigate.schedulerInvitePage(scheduler?.id) },
                ]} />
            </div>
            <section className="pageheader-section-min">
                <div className="container">
                    <div className="section-wrapper text-center">
                        <h2 className="pageheader-title mt-3">Invite User to {scheduler?.name}</h2>
                        <p className="lead">Invite a user to particpate in the leaving updates for this scheduler and generating social media content.</p>
                    </div>
                </div>
            </section>

            <div className="container my-5">
                {success && <Success message={success} />}
                {errors.message && <Danger message={errors.message} />}

                <div className="mb-4">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search users by username, display name, or email"
                        value={searchTerm}
                        onChange={handleSearchChange}
                    />
                </div>

                <div className="list-group mb-4 text-black">
                    {filteredUsers.map((userWrapper) => {
                        const user = userWrapper.user;
                        return (
                            <label key={user.id} className="list-group-item">
                                <input
                                    type="radio"
                                    name="selectedUser"
                                    value={user.id}
                                    checked={selectedUserId === user.id}
                                    onChange={() => setSelectedUserId(user.id)}
                                />
                                <span className="ml-2">
                                    <img src={user.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} alt="Avatar" className="rounded-circle mr-2" width="40" height="40" />
                                    {user.username}
                                </span>
                            </label>
                        );
                    })}
                </div>

                <div className="text-center">
                    <p>If you do not see the user you want add above, then first invite them to the community associated with {title?.name} below.</p>
                    <button className="btn btn-primary mr-2 me-2" onClick={handleInviteUser} disabled={isInviting}>
                        {isInviting ? 'Adding...' : 'Add User'}
                    </button>
                    <button className="btn btn-secondary" onClick={handleInviteNewUser}>
                        Invite New User to Community
                    </button>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default SchedulerInviteUserPage;
