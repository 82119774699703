// src/pages/titles/SchedulerUsersPage.jsx

import React, { useState, useEffect } from 'react';
import Glitch from 'glitch-javascript-sdk';
import { useParams, useNavigate, Link } from 'react-router-dom';
import PublisherHeader from '../../component/layout/publisherheader';
import Footer from '../../component/layout/footer';
import Loading from '../../component/alerts/Loading';
import Danger from '../../component/alerts/Danger';
import Success from '../../component/alerts/Success';
import Navigate from '../../../../util/Navigate';
import Breadcrumbs from '../../component/layout/breadcrumb';
import timeouts from '../../../../constants/timeouts';
import ScheduleNavbar from '../../component/section/schedulers/schedule_navbar';

const SchedulerUsersPage = () => {
  const { id } = useParams();
  const [scheduler, setScheduler] = useState(null);
  const [title, setTitle] = useState(null);
  const [admins, setAdmins] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!Glitch.util.Session.isLoggedIn()) {
      const currentUrl = window.location.href;
      navigate(Navigate.authLogin() + '?redirect=' + currentUrl);
    } else {
      setCurrentUserId(Glitch.util.Session.getID());
      fetchTitleData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchTitleData = async () => {

    
    try {
      const reponseScheduler = (await Glitch.api.Scheduler.getSchedule(id));

      const responseTitle = await Glitch.api.Titles.view(reponseScheduler.data.data.title_id);
      setScheduler(reponseScheduler.data.data);
      setTitle(responseTitle.data.data);
      setAdmins(responseTitle.data.data.admins || []);
    } catch (error) {
      console.error('Error fetching title data:', error);
      setErrors({ message: 'Error fetching title data' });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveSelf = async () => {

    if(admins.length == 1) {
        alert("You cannot remove yourself if you are the only user.");
    }
    try {
      await Glitch.api.Titles.removeAdministrator(title.id, currentUserId);
      setSuccess('You have been removed from the administrators.');
      setAdmins((prevAdmins) => prevAdmins.filter((admin) => admin.id !== currentUserId));
      setTimeout(() => {
        navigate(Navigate.schedulerListPage());
      }, timeouts.redirect_timeout);
    } catch (error) {
      console.error('Error removing yourself:', error);
      setErrors({ message: 'Error removing yourself from administrators' });
    }
  };

  if (isLoading) {
    return (
      <>
        <PublisherHeader position={"relative"} />

        <div className="container my-5 text-center">
          <Loading />
        </div>
        <Footer />
      </>
    );
  }

  return (
    <>
      <PublisherHeader position={"relative"} />
      <div className="container mt-4">
        <Breadcrumbs items={[
           { name: 'Schedulers', link: Navigate.schedulerListPage() },
           { name: scheduler?.name, link: Navigate.schedulerViewPage(scheduler?.id) },
           { name: 'Users', link: Navigate.schedulerUsersPage(scheduler?.id) },
        ]} />
      </div>
      <section className="pageheader-section-min">
        <div className="container">
          <div className="section-wrapper text-center">
            <h2 className="pageheader-title mt-3">{scheduler?.name} Users</h2>
            <p className="lead">Specify which users will receive weekly notifications to submit content for the scheduler.</p>
          </div>
        </div>
      </section>

      <div className="container mt-5">
            <ScheduleNavbar schedulerId={id} />
      </div>

      <div className="container my-5">
        <div className="mb-4 text-right">
          {title?.community_id ? (
            <Link to={Navigate.schedulerInvitePage(scheduler?.id)} className="btn btn-primary">
              Invite User
            </Link>
          ) : (
            <div className="alert alert-warning">
              This title does not belong to a community/business account. To invite users, please associate this title with a community.
            </div>
          )}
        </div>

        {success && <Success message={success} />}
        {errors.message && <Danger message={errors.message} />}

        <h3>Administrators</h3>

        <div className="list-group">
          {admins.map((admin) => (
            <div key={admin.id} className="list-group-item d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center text-black">
                <img src={admin.avatar || 'https://storage.googleapis.com/glitch-production-images/template1-images/gamer.png'} alt="Avatar" className="rounded-circle mr-3" width="50" height="50" />
                <div>
                  <h5 className="mb-1 text-black">{admin.username}</h5>
                  <p className="mb-0">{admin.display_name}</p>
                </div>
              </div>
              {admin.id === currentUserId && (
                <button className="btn btn-danger" onClick={handleRemoveSelf}>
                  Remove Myself
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SchedulerUsersPage;
